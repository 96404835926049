import { useEffect, useState } from "react";
import { Input,message,Select,Carousel,Drawer,Modal} from 'antd';
import { Link, useNavigate  } from 'react-router-dom'
import {httpGet,httpPost,host_url} from '../../utils/httpUtils';
import { Common } from "../../app/Common";
import { Tabs,Popup} from 'antd-mobile';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
export function CBadgeList() {
	const navigate = useNavigate();
	const [myNftList,setMyNftList] =  useState([]as any);
	const [modalOpenStatus, setModalOpenStatus] = useState(false);
	const [unfinished,setUnfinished] =  useState([]as any);
	const [badgeMint,setBadgeMint] =  useState(false);
	const [nftList,setNftList] =  useState([]as any);
	
	const [walletAddress, setWalletAddress] = useState('')
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [mintSelect, setMintSelect] = useState(0);
	
	function getMyNftList(){
		httpGet("/memberNft/myNftList").then((e:any)=>{
			if(e.data.code == 0 ){
				setMyNftList(e.data.data);
			}
		})
	}

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	function  getUnfinished(){
		httpGet("/memberNft/unfinished").then((e:any)=>{
			if(e.data.code == 0 ){
				setUnfinished(e.data.data);
			}
		})
	}
	function getNftList(){
		let cpformData={...formData}
		httpPost("/memberNft/nftList",cpformData).then((e:any)=>{
			if(e.data.code == 0 ){
				if(e.data.data.length > 0 ){
					setPersonMenu(e.data.data[0].name)
				}
				setNftList(e.data.data);
			}
		})
	}
	useEffect(()=>{
			getUnfinished();
			getMyNftList();
			getNftList();
			getPlateAll();
	},[]);

	function  memberCheck(){
		httpGet("/member/getMember").then((e:any)=>{
			if(e.data.code == 401 ){
				localStorage.setItem("pageFrom","/#/BadgeList");
				navigate("/SignIn");
			}
		})
	}


	const [plateList,setPlateList] =  useState([] as Array<any>);

	function getPlateAll(){
		httpGet("/project/getPlateAll").then((e:any)=>{
			if(e.data.code==0){
				let list=[];
				for (let index = 0; index < e.data.data.length; index++) {
					const item = e.data.data[index];
					list.push({value:item.plateName,label:item.plateName,tokenList:item.tokenList});
				}
				list.unshift({label:"All",value:"All"});
				setPlateList([...list]);
			}
		})
	}

	function memberNft(){
		if(mintSelect == 1){
			message.error("Please input your Polygon Address ");
			return;
		}
		httpPost("/memberNft/mint",{walletAddress:walletAddress}).then((e:any)=>{
			if(e.data.data ==0){
				setModalOpenStatus(false)
			}else{
				setModalOpenStatus(true)
			}
			setBadgeMint(false);
			setIsModalOpen(true);
		})
	}

	function getSkeleton(){
		return  <div className="badge_box" >
				 <Skeleton count={1}   style={{height:"2.9167vw",width:"100%"}} />
				<div  style={{display:"flex"}} >
				<div className="badge_box_group_item" style={{marginRight:20}} >
					<Skeleton count={1}  width="12.5vw" height={"10.4167vw"}  />
					<Skeleton count={1}  height={50} />
					<Skeleton count={1}  height={50} /> 
				 </div>
				</div>
			 </div> 
   }



	const [formData,setFormData] =  useState({tags:[]} as any);


	function getMinStep(fundRaisingObjectives:any,donateNum:any){
		let maxWidth=6.25;
		let width =maxWidth/fundRaisingObjectives*donateNum;
		return <div className="donate_step_bule" style={{width:width>maxWidth?`${maxWidth+"vw"}`:`${width+"vw"}`}} >
		</div>
	}


	function getMinStepM(fundRaisingObjectives:any,donateNum:any){
		let maxWidth=42.6667;
		let width =maxWidth/fundRaisingObjectives*donateNum;
		return <div className="mdonate_list_item_cld_num_step" style={{width:width>maxWidth?`${maxWidth+"vw"}`:`${width+"vw"}`}} >
		</div>
	}

	const [forumList,setForumList] =  useState([]as Array<any>);
	function selectAll(){
		 let plateName=[];
		for (let index = 0; index < plateList.length; index++) {
			plateName.push( plateList[index].value);
		}
		formData.plateName=plateName;
		setFormData({...formData});
	}

	function goProject(id:any){
		if(id){
			navigate("/Project?projectId="+id);
		}else{
			message.error("Projects without  donations");
		}
	}



	function getTagsBut() {
		let color_conf={
				a:"#274554",b:"#279D8E",c:"#E9C468",d:"#F5A261",e:"#E77150",f:"#A56698",g:"#65B8BA",h:"#279D75",i:"#3D6799",j:"#71BB63",k:"#457B9D",l:"#D67370",m:"#FFB4A2",
				n:"#E5979A",o:"#B4838D",p:"#6D6674",q:"#F8A091",r:"#5DC572",s:"#C9CA7B",t:"#E088A9",u:"#FFC79A",v:"#9D5FB4",w:"#5A87AD",x:"#DAAF7E",y:"#6CBFC2",z:"#6F74BC"
			}
		
		let  Mobile=  <div  style={{width:"95vw",marginTop:10,overflow:"auto",whiteSpace:"nowrap",height:40}} >
			{ plateList.length > 0 ? plateList.map((item,indx)=>{
			  let color = ''
			  if (item.value) {
				color = color_conf[item.value.substring(0, 1).toLowerCase()]
				  ? color_conf[item.value.substring(0, 1).toLowerCase()]
				  : '#FFB4A2'
			  }

			  return <span key={indx}  onClick={()=>{
				  if(item.value =='All'){
					formData.plateName="";
				  }else{
					formData.plateName=item.value;
				  }
				setFormData({...formData})
				getNftList();
			  }} className='all_tags_but'  style={{background:color,opacity:  formData.plateName== item.value ||  formData.plateName ==''   ? 1:0.8}} >{item.value}</span>
			}) : <Skeleton count={1}   style={{height:"80px",width:"100%"}} />
			}
		</div>
	
		let  Web=  <div  style={{width:"100%",marginTop:10,display:"flex",flexWrap:"wrap"}} >
		  {plateList.length > 0 ? plateList.map((item,indx)=>{
			let color = ''
			if (item.value) {
			  color = color_conf[item.value.substring(0, 1).toLowerCase()]
				? color_conf[item.value.substring(0, 1).toLowerCase()]
				: '#FFB4A2'
			}
			return <div key={indx}  onClick={()=>{
			  if(item.value =='All'){
				formData.plateName="";
			  }else{
				formData.plateName=[item.value];
			  }
			  setFormData({...formData})
			  getNftList();
			}} className='call_tags_but but-shadow'  style={{background:color,opacity: formData.plateName== item.value ||  formData.plateName =='' ? 1:0.8}} >{item.value}</div>
		  }):  <Skeleton count={1}  style={{height:40}} />
		  }
	</div>
	
		return Common.isMobile ? Mobile : Web
	  }

	function tw(id:any) {
		let shar_url=host_url+"/#/BadgeDetail?detailId="+id;
		let metaArr = [
			'twitter:title','',
			'twitter:card', 'summary_large_image',
			'twitter:url', shar_url,
		]
		let metaParams = metaArr.toString()
		metaParams = encodeURIComponent(encodeURIComponent(metaParams))
		window.open(`https://twitter.com/share?text=Here’s my Impact badge from Impactopia, please check it out&url=${host_url}/api/app/share/new?meta=${metaParams}`)
	}


	const [personMenu,setPersonMenu] =  useState("");


	function windOpen(url:any){
		window.open(url);
	}
	let Web= <div  className="badge_body" >
		<div  className="badge_your" >
		<Carousel className="badge_your_carousel"  dots={false}  autoplay>
			{unfinished && unfinished.length > 0 ? unfinished.map((item:any,indx:any)=>{
				return <div  key={indx} >
				<div className="badge_your_current" >
					<div  className="badge_your_current_left"   onClick={()=>{	navigate("/BadgeDetail?detailId="+item.itemId);}}  >
						<img src={JSON.parse(item.image)[0].url}  className="badge_your_current_left_img" alt="" />
						<div className="badge_your_current_left_name"  >
							{item.name}
						</div>
					</div>
					<div  className="badge_your_current_right">
						<div className="badge_your_current_right_text" >
							You're about to get this badge.
						</div>
						<div className="badge_your_current_right_token"  onClick={()=>{	navigate("/BadgeDetail?detailId="+item.itemId);}} >
							{item.donationAmount} / {item.handselValue}{item.tokenName}
						</div>
						<div className="badge_your_current_but"  >
							<div  className=" donate_list_item_cld_num "  onClick={()=>{	navigate("/BadgeDetail?detailId="+item.itemId);}}  >
								<div className="donate_step" >{getMinStep(item.handselValue,item.donationAmount)}</div>
							</div>
							<div  className="mbadge_claim but-shadow" onClick={()=>{setBadgeMint(true)}} >
								Claim
							</div>
							<div className="mbadge_box_group_item_tw but-shadow" style={{}} onClick={()=>{goProject(item.projectId)}} >
								Donate
								<img src={require("../../assets/v2/go.png")}  className="badge_your_current_right_go_img" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>

			}):""}
 	 	</Carousel>

			<div className="badge_your_list" >
				<div>
					You have obtained these badges.
				</div>
				<div className="badge_your_img_list">
					{myNftList ?  myNftList.map((item:any,indx:any)=>{
					  return	<img   onClick={()=>{
						navigate("/BadgeDetail?detailId="+item.nftItemId);
					}} key={indx} src={JSON.parse(item.image)[0].url}  className="badge_your_list_img" alt="" />
					}): <div></div> }
				</div>

				<div className="badge_your_list_view but-shadow" >
					<Link  to={'/Person'}   state={{ type: "NFTbadge"}}  >
					<div>
						<div className="badge_your_list_view_1" >View</div>
						<div className="badge_your_list_view_1" >All</div>
					</div>
					</Link>
				</div>
			</div>
		</div>
		<div  className="badge_options" >
				<div className="badge_options_seacher" >
					{/* <div className="badge_options_seacher_all" onClick={selectAll} >ALL</div> */}
					{/* <Select
						bordered={false}
						allowClear={true}
						mode="multiple"
						style={{width:"85%"}}
						value={formData.plateName}
						showArrow
						onChange={(e,op)=>{
							formData.plateName=e;
							setFormData({...formData});
						}}
						options={plateList}
						/> */}
					<div className="badge_options_seacher" >
					<div className="badge_options_seacher_box" >
						<Input style={{width:"80%"}}  onChange={(e)=>{
							formData.keyWord=e.target.value;
							formData({...formData});
						}} bordered={false} placeholder="Basic usage" />
					</div>
					<div className="badge_options_seacher_filters" onClick={getNftList} >
						<img src={require("../../assets/v2/filter.png")}  className="badge_box_group_item_filters" alt="" />
						Filters
					</div>
					</div>
				</div>

				
				{getTagsBut()}

			
				
		</div>

		<div  className="badge_box" >
				{nftList && nftList.length > 0 ? nftList.map((item:any,inx:any)=>{
				  return	item.itemList && item.itemList.length>0  ?  <div  key={inx} className="badge_box_group" >
					  <div className="badge_box_group_text" ><span  style={{fontWeight:900}} ></span> {item.useDirections}</div>
					  <div  className="badge_box_group_item_list" >
					   {
						  item.itemList ? item.itemList.map((ite:any,indx:any)=>{
							  return <div key={indx} className="badge_box_group_item"  >
											  <img src={JSON.parse(ite.image)[0].url}  onClick={()=>{navigate("/BadgeDetail?detailId="+ite.id)}} className="badge_box_group_item_img" alt="" />
											  <div className="badge_box_group_item_content" >
												  <div className="badge_box_group_item_content_name" onClick={()=>{navigate("/BadgeDetail?detailId="+ite.id)}}  >
													  {ite.name}
												  </div>
												  <div   className="badge_box_group_item_content_text" onClick={()=>{navigate("/BadgeDetail?detailId="+ite.id)}}  >
													  {ite.description}
												  </div>
												  <div className="badge_box_group_item_but"  >
													  <div   className="badge_box_group_item_polygon"  >
													  	{/* <img src={require("../../assets/v2/polygon.png")}  className="badge_box_group_item_polygon_img" alt="" /> */}
													  </div>
													  <div  className="badge_box_group_item_tw" >
													  <img src={require("../../assets/v2/tw.png")} onClick={()=>{tw(ite.id)}}   className="badge_box_group_item_tw_img" alt="" />
													  </div>
												  </div>
											  </div>
										  </div>

						  }):""
					   }
				  </div>
				  </div>:  ""
				}): getSkeleton()}
		</div>
		<Drawer title="Badge mint" placement="right"    width={"24.8542vw"} onClose={()=>{setBadgeMint(false)}} open={badgeMint}>
			<div style={{paddingTop:"10.4167vw",width:"100%"}} >
			<div className="flex-align-center" >
					<div className={mintSelect == 0? 'badgemint_only badgemint_only_active':'badgemint_only'}  onClick={()=>{
						setMintSelect(0)
					}} >
							On Impactopia Only
					</div>
				</div>
				<div className="flex-align-center" >
					<div className={mintSelect == 1? 'badgemint_only badgemint_only_active':'badgemint_only'} onClick={()=>{
						setMintSelect(1)
					}} >
						On Impactopia and On Polygon
					</div>
				</div>
				{
					mintSelect == 1?<div className="flex-align-center" style={{marginTop:" 1.0417vw"}} >
					<Input placeholder="Your Polygon Address"
					value={walletAddress}
					onChange={(e)=>{
						setWalletAddress(e.target.value);
					}}
					bordered={false} style={{ width: "18.75vw", height: "2.5vw",background:"#FCFCFC" }} />
				</div>:""
				}
				<div className="flex-align-center"  onClick={memberNft} style={{marginTop:" 5.4167vw"}} >
					<div  className="badgemintBut" >
						Continue
					</div>
				</div>
			</div>
		</Drawer>
		<Modal closable={false}   bodyStyle={{ borderRadius:"20px",backgroundColor: "transparent", width: "100%" }} footer={null} onOk={handleOk} onCancel={handleCancel}  >
				{modalOpenStatus?<div className="mint_success-img"  >
					<div className="flex-align-center">
					<div className="donationMoney_but" onClick={()=>{
						setIsModalOpen(false)
					}} >Good</div>
				</div>
			</div>:<div className="mint_failed-img"  >
				<div className="flex-align-center">
					<div className="donationMoney_fail_but" onClick={()=>{
						setIsModalOpen(false)
					}} >Continue</div>
				</div>
			</div>}
		</Modal> 
	</div>


		let  Mobile=<div>
			<Carousel className="mbadge_your_carousel"  dots={false}  autoplay>
					{unfinished && unfinished.length > 0 ? unfinished.map((item:any,indx:any)=>{
						return <div  key={indx} >
						<div className="mbadge_your_current" >
							<div  className="mbadge_your_current_left"   onClick={()=>{	navigate("/BadgeDetail?detailId="+item.itemId);}}  >
								<img src={JSON.parse(item.image)[0].url}  className="mbadge_your_current_left_img" alt="" />
								<div className="mbadge_your_current_left_name"  >
									{item.name}
								</div>
							</div>

							<div  className="mbadge_your_current_right">
								<div className="mbadge_your_current_right_text" >
									You're about to get this badge.
								</div>
								<div className="mbadge_your_current_right_token"  onClick={()=>{	navigate("/BadgeDetail?detailId="+item.itemId);}} >
									{item.donationAmount} / {item.handselValue}{item.tokenName}
								</div>
								<div className="mbadge_your_current_step"  >
									<div  className="mdonate_list_item_cld_num "  onClick={()=>{	navigate("/BadgeDetail?detailId="+item.itemId);}}  >
										<div className="mdonate_step" >{getMinStepM(item.handselValue,item.donationAmount)}</div>
									</div>
								</div>
							

									<div className="mbadge_your_current_but" >
											<div  className="mbadge_your_current_claim" onClick={()=>{setBadgeMint(true)}} >
												Claim
											</div>

											<div className="mbadge_your_current_right_go" onClick={()=>{goProject(item.projectId)}} >
												Donate <img src={require("../../assets/v2/go.png")}  className="mbadge_your_current_right_go_img" alt="" />
											</div>
									</div>
							</div>
						</div>
					</div>
					}):""}
				</Carousel>

				<Tabs  activeKey={personMenu} onChange={(e)=>{
				setPersonMenu(e);
				}} >

						{nftList && nftList.length > 0 ? nftList.map((item:any,inx:any)=>{
							return <Tabs.Tab title={item.name} key={item.name}>
								 <div  className="mbadge_box_group_item_list" >
										{
											item.itemList ? item.itemList.map((ite:any,indx:any)=>{
												return <div key={indx} className="mbadge_box_group_item"  >
																<img src={JSON.parse(ite.image)[0].url}  onClick={()=>{navigate("/BadgeDetail?detailId="+ite.id)}} className="mbadge_box_group_item_img" alt="" />
																<div className="mbadge_box_group_item_content" >
																	<div className="mbadge_box_group_item_content_name" onClick={()=>{navigate("/BadgeDetail?detailId="+ite.id)}}  >
																		{ite.name}
																	</div>
																	<div   className="mbadge_box_group_item_content_text" onClick={()=>{navigate("/BadgeDetail?detailId="+ite.id)}}  >
																		{ite.description}
																	</div>
																	<div className="mbadge_box_group_item_but"  >
																		<div   className="badge_box_group_item_polygon"  >
																			{/* <img src={require("../../assets/v2/polygon.png")}  className="badge_box_group_item_polygon_img" alt="" /> */}
																		</div>
																		<div  className="mbadge_box_group_item_tw" >
																		<img src={require("../../assets/v2/tw.png")} onClick={()=>{tw(ite.id)}}   className="mbadge_box_group_item_tw_img" alt="" />
																		</div>
																	</div>
																</div>
															</div>

											}):""
										}
									</div>
							</Tabs.Tab>
						}):""}
		  </Tabs>
		
		  <Popup  bodyClassName="mbadgemint_popup" visible={badgeMint} position='bottom' onMaskClick={() => {setBadgeMint(false)}} bodyStyle={{padding:"20px" }}>
			<div style={{paddingTop:"10.4167vw",width:"100%"}} >
			<div className="flex-align-center" >
					<div className={mintSelect == 0? 'mbadgemint_only mbadgemint_only_active':'mbadgemint_only'}  onClick={()=>{
						setMintSelect(0)
					}} >
							On Impactopia Only
					</div>
				</div>
				<div className="flex-align-center" >
					<div className={mintSelect == 1? 'mbadgemint_only mbadgemint_only_active':'mbadgemint_only'} onClick={()=>{
						setMintSelect(1)
					}} >
						On Impactopia and On Polygon
					</div>
				</div>
				{
					mintSelect == 1?<div className="flex-align-center" style={{marginTop:" 1.0417vw"}} >
					<Input placeholder="Your Polygon Address"
					value={walletAddress}
					onChange={(e)=>{
						setWalletAddress(e.target.value);
					}}
					bordered={false} style={{ width: "85.3333vw", height: "11.7333vw",background:"#FCFCFC" }} />
				</div>:""
				}

				<div className="flex-align-center"  onClick={memberNft} style={{marginTop:" 5.4167vw"}} >
					<div  className="mbadgemintBut" >
						Continue
					</div>
				</div>
			</div>
		</Popup>


		<Popup  bodyClassName="mbadgemint_popup" visible={isModalOpen} position='bottom' onMaskClick={() => {setIsModalOpen(false)}} bodyStyle={{padding:"20px" }}>
			{modalOpenStatus?<div className="m_mint_success-img"  >
					<div className="flex-align-center">
					<div className="mdonationMoney_but" onClick={()=>{
						setIsModalOpen(false)
					}} >Good</div>
				</div>
			</div>:<div className="m_mint_failed-img"  >
				<div className="flex-align-center">
					<div className="mdonationMoney_fail_but" onClick={()=>{
						setIsModalOpen(false)
					}} >Continue</div>
				</div>
			</div>}
		</Popup>

		</div>



return Common.isMobile ? Mobile : Web
}
