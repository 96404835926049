import { useEffect, useState } from "react";
import { Input, message } from 'antd';
import { Link, useNavigate  } from 'react-router-dom'
import axios from 'axios';
import { State, useAppDispatch, useAppSelector } from "../../app/Store";
import { ChainId, ethereum, web3 } from "../../app/Config";
import { Common } from "../../app/Common";

import {
	ConnectSelectors,
	ConnectTask,
	disconnect,
	SwitchTask,
	updateAddress,
	updateBalance,
	updateChainId
 } from "../../slices/ConnectSlice";

 import {httpGet,httpPost} from '../../utils/httpUtils';
import { type } from "os";
export function CForgotPassword() {

	const dispatch = useAppDispatch();
	const isConnected = useAppSelector(ConnectSelectors.isConnected);
	const isLoading = useAppSelector(ConnectSelectors.isLoading);
	const displayAddress = useAppSelector(ConnectSelectors.displayAddress);
	const { address, chainId } = useAppSelector(ConnectSelectors.userData);

	const text = isLoading ? "CONNECTING..." :
	isConnected ? chainId === ChainId ?
	  'wallet sign' : "SWITCH" : "CONNECT";
	  
	function connect() {
		!isConnected && !isLoading && dispatch(ConnectTask());
	  }
	  function switch_() {
		dispatch(SwitchTask())
	  }

	  async function sign_wallet(){
		const signature =  await web3.eth.personal.sign("welcome to impact", address,"welcome to impact");
		httpPost('/member/bindWallet',{address:address,signature:signature,message:"welcome to impact"}).then(
			(e:any) => {
			  if(e.data.code == 0){
				navigate("/");
			  }
			}
		)
	  }

	  const onClick = isLoading ? undefined :
		isConnected ? chainId === ChainId ?
		sign_wallet : switch_ : connect;



	const navigate = useNavigate();
	interface MemberInfo{
	   userName:any,
	   password:any,
	   email:any,
	   code:any

	}
	const [member,setMamber] =  useState({}as MemberInfo );
	const [step,setStep] =  useState(1);


	function Send(){
		if(!member.email){
			message.error("email is Required ");
			return;
		}
		httpGet('/member/sendEmailForgetPassword/'+member.email).then(
			( e:any) => {
			   if(e.data.code == 0){
				  message.success("send emil  captcha success");
			   }
			 }
		 )

	}


	function  renderGoogle(){
		httpPost('/oauth/render',{}).then(
			( e:any) => {
			   window.open(e.data.data);
			 }
		 )
	}



	async function signUp(){

		if(!member.email){
			message.error("email is Required ");
			return;
		}

		if(!member.password){
			message.error("password is Required ");
			return;
		}

		if(!member.code){
			message.error("code is Required ");
			return;
		}

		httpPost('/member/retrievePassword',{...member}).then(
		  ( e:any) => {
			 if(e.data.code == 0){
				// localStorage.setItem("token",e.data.token);
				// localStorage.setItem("member",JSON.stringify(e.data.member));
				navigate("/SignIn");
			 }
		   }
	   )
	}
	
	let  Web= <div className="sign_body" >
	<div className="sign_left" onClick={()=>{navigate("/")}}   ></div>
	<div className="sign_right" >
		<div className="sign_create" >
			Forget Password
		</div>
	
		<div>
			<div className="sign_or" >
			<div className="sign_ds">
				<img src={require("../../assets/v2/g@2x.png")}   style={{width:'68px',height:'68px',marginLeft:"0.825vw"}}  alt="" />
			</div>
			</div>
			<div className="sign_or">
				-OR-
			</div>

			<div className="sign_full_email">
				<Input placeholder="Email Address"  onChange={(e)=>{
					member.email=e.target.value;
					setMamber({...member});
				}}  bordered={false} style={{width:"29.1667vw"}} />
			</div>

			<div className="sign_full_password">
				<Input placeholder="Password" onChange={(e)=>{
					member.password=e.target.value;
					setMamber({...member});
				}}  type="password" bordered={false} style={{width:"29.1667vw"}} />
			</div>

			<div className="sign_full_password">
				<Input placeholder="email Captcha" onChange={(e)=>{
					member.code=e.target.value;
					setMamber({...member});
				}}  bordered={false} style={{width:"20.1667vw"}} />

					<div className="sign_send_but"  onClick={Send} >
						Send
					</div>
			</div>

			<div className="sign_but but-shadow"  onClick={signUp} >
				Update Account
			</div>
		</div>
		


		<div className="sign_to_login">
			Already have an account? <Link  to={"/SignIn"} > <span  className="sign_to_login_1" >Sign In</span> </Link>
		</div>
	</div>
</div>

let  Mobile=<div>
<div className="sign_right_m" >
	<div className="sign_create_m" >
		Sign In Impactopia
	</div>
	<div className="sign_other" >
		<div className="sign_or_m">
			<img   onClick={renderGoogle} src={require("../../assets/v2/g@2x.png")} style={{width:'68px',height:'68px',marginLeft:"0.825vw"}}  alt="" />
		</div>
	</div>
	<div className="sign_or_m">
		-OR-
	</div>

	<div style={{marginTop:60,display:"flex",justifyContent:"flex-start",width:"100%",paddingLeft:10,fontSize:18}} >
		Email Address
	</div>
	<div className="sign_full_name_m">
		<Input  value={member.userName} onChange={(e)=>{
			 member.email=e.target.value;
			 setMamber({...member});
		}}
		 placeholder="Email Address"      bordered={false}  className="sign_input" style={{display:"flex",alignItems:"flex-end"}} />
	</div>
	<div>
	</div>

	<div style={{marginTop:42,display:"flex",justifyContent:"space-between",width:"100%",paddingLeft:10,paddingRight:10,fontSize:18}} >
		<div>Password</div>
	</div>

	<div className="sign_full_name_m"  >
		<Input value={member.password} onChange={(e)=>{
			 member.password=e.target.value;
			 setMamber({...member});
		}}  placeholder="Password"  type="password"  bordered={false}  />
	</div>


	<div className="sign_full_name_m"  style={{marginTop:30,paddingRight:40}} >
	<Input placeholder="email Captcha" onChange={(e)=>{
		member.code=e.target.value;
		setMamber({...member});
	}}  bordered={false} style={{width:"70%"}} />

		<div className="sign_send_f_but_m"  onClick={Send} >
			Send
		</div>
</div>


	<div className="sign_but_m"  onClick={signUp} >
			Update Account
	</div>
	<div className="sign_to_login_m">
	   Already have an account? <Link  to={"/SignIn"} > <span  className="sign_to_login_1"   style={{fontSize:"2.2vw"}} >Sign In</span></Link>
	</div>
</div>
</div>

return Common.isMobile ? Mobile : Web


}
