import { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom'
import { Select,Drawer,InputNumber , Button, Tabs,Modal} from 'antd';
import { httpGet, httpPost } from '../../utils/httpUtils';
import { Formatter } from './Formatter';
import { Common } from "../../app/Common";
import "./CPerson_donation.css"

type IProps = {
  memberId?: any,
  email:any,
}

export function CPerson_donation(props: IProps) {
  console.log(props);
  const [myDonateList, setMyDonateList] = useState({} as any)
  const [everyOrgList, setEveryOrgList] = useState([] as any)

  

  useEffect(() => {
    if (props.memberId) {
      getMyDonateList(props.memberId);
    }
  }, [props.memberId])


  function getMyDonateList(memberId: any) {
    httpGet('/donaterecord/myDonateGroup/' + memberId).then((e: any) => {
      if (e.data.code == 0) {
        setMyDonateList(e.data.data)
      }
    })
  }


  function getEveryOrgList(memberId: any) {
    httpGet('/donaterecord/everyOrgList/' + memberId).then((e: any) => {
      if (e.data.code == 0) {
        setEveryOrgList(e.data.data)
      }
    })
  }


  

  const [activeKey,setActiveKey] =  useState("MyDonation");

  function tabsChange(e:any){
    setActiveKey(e);
    if(e =='MyDonation'){
        getMyDonateList(props.memberId);
    }else{
        getEveryOrgList(props.memberId);
    }
    
  }

  function donate(item:any){
		let url=`https://www.every.org/${ JSON.parse(item.everyOrgToNonprofit).slug}?email=${props.email}&webhook_token=YgR5EDAYXwdezDYP9oUVeAmv&success_url=https%3A%2F%2Fwww.impactopia.net%2F%23%2FDonateUs&exit_url=https%3A%2F%2Fwww.impactopia.net%2F%23%2FDonateUs#donate/card`;
		window.open(url);
	}
  
  const navigate = useNavigate();
  let Web = <div className="person_donation" style={{ marginTop: 30 }}>
    <Tabs onChange={tabsChange}  defaultActiveKey={activeKey} >
      <Tabs.TabPane tab="My Donation" key="MyDonation">
            {myDonateList && myDonateList.length > 0 ? (
            myDonateList.map((ite: any, idx: any) => {
              return (
                <div key={idx} className="person_reading">
                  <div className="person_reading_text">{ite.projectName}</div>
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{ width: '50%', display: 'flex' }}
                      className="person_reading_eth">
                      <div>
                        {ite.donateNum}Eth / {ite.fundRaisingObjectives}Eth
                      </div>
                    </div>

                    <div
                      style={{
                        width: '50%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}>
                      <Link to={'/Project?projectId=' + ite.projectId}>
                        <div className="person_view_but but-shadow" >View all</div>
                      </Link>
                    </div>
                  </div>
                  {(ite.donateRecordList && ite.donateRecordList.length) >
                    0 ? (
                    ite.donateRecordList.map((item: any, index: any) => {
                      return (
                        <div key={index} className="person_reading_list">
                          <div className="person_reading_item">
                            <div className="person_reading_item_1">
                              {index + 1}
                            </div>
                            <img
                              className="person_reading_item_img"
                              src={require('../../assets/v2/gold.png')}
                              alt=""
                            />
                            <div className="person_reading_item_2">
                              {item.memberName}
                            </div>
                            <div className="person_reading_item_3">
                              transaction {item.amountEth ? item.amountEth : 0}
                              {ite.receivingToken}.
                            </div>
                            <div className="person_reading_item_4">
                              <Formatter
                                dateTime={item.createDateTime}></Formatter>
                            </div>
                            {/* <div
                              className="person_reading_item_5"
                              onClick={() => {
                                window.open(
                                  'https://goerli.etherscan.io/tx/' +
                                  item.txnhash
                                )
                              }}>
                              wallet:{item.senderAddress}
                            </div> */}
                        <div className="project_reading_item_5" >
                                      {item.senderAddress}
                                      </div>

                            
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <div></div>
                  )}
                </div>
              )
            })
          ) : (
            <div style={{width:"100%",justifyContent:"center",display:"flex"}} > <div  className="no_list" >You don’t have any donation yet!</div></div>
          )}


      </Tabs.TabPane>
      <Tabs.TabPane tab="Every.org" key="Every.org">
        {
            everyOrgList.map((item: any, index: any) => {
              return (
                <div  onClick={()=>{donate(item)}} key={index} className="person_reading_list">
                  <div className="person_reading_item">
                    <div className="person_reading_item_1">
                      {index + 1}
                    </div>
                    <div className="person_reading_item_2">
                      {JSON.parse(item.everyOrgToNonprofit).name}
                    </div>
                    <div className="person_reading_item_4">
                        Donated   {  `  $`+item.usdAmount}
                   </div>
                    <div className="person_reading_item_4">
                      <Formatter
                        dateTime={item.createDateTime}></Formatter>
                    </div>
                    <div className="person_reading_item_2">
                        {item.everyOrgTags}
                    </div>
                  </div>
                </div>
              )
            })

        }
            

        
      </Tabs.TabPane>

    </Tabs>



  </div>




  let Mobile = <div className="mperson_donation_box" >
     <div className='followBut'>
			<div className={activeKey === 'MyDonation' ? 'followsel' : 'followNoSel'} onClick={() => tabsChange('MyDonation')} >My Donation</div>
      <div className={activeKey === 'Every.org' ? 'followsel' : 'followNoSel'} onClick={() => tabsChange('Every.org')}  >Every.org</div>
		</div>
    {activeKey == 'MyDonation' ?myDonateList && myDonateList.length > 0 ? (
      myDonateList.map((ite: any, idx: any) => {
        return (
          <div key={idx} className="mcperson_reading">
            <div style={{ display: 'flex' }} className="mperson_donation_projcet" >
              <div
                style={{ width: '70%' }} >
                <div className="mperson_reading_text">{ite.projectName}</div>
                <div className="mperson_reading_eth">
                  {ite.donateNum}{ite.receivingToken} / {ite.fundRaisingObjectives}{ite.receivingToken}
                </div>
              </div>

              <div
                style={{
                  width: '30%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}>
                <Link to={'/Project?projectId=' + ite.projectId}>
                  <div className="mperson_view_but">View all</div>
                </Link>
              </div>
            </div>
            <div className="mperson_reading_list">
              {(ite.donateRecordList && ite.donateRecordList.length) >
                0 ? (
                ite.donateRecordList.map((item: any, index: any) => {
                  return (
                    <div key={index} className="mperson_reading_item">
                      <div className="mperson_reading_item_line" >
                        <div className="mperson_reading_item_3">
                          {index + 1}
                        </div>
                        <div className="mperson_reading_item_3 " style={{ marginLeft: "2.6667vw" }} >
                          Donated <span style={{ color: "rgba(82, 165, 83, 1)" }} >{item.amountEth ? item.amountEth : 0}</span>
                          {ite.receivingToken}.
                        </div>
                      </div>

                      <div className="mperson_reading_item_line" style={{ paddingLeft: "5.0333vw", color: "#808080" }} >
                        <div >
                          <Formatter
                            dateTime={item.createDateTime}></Formatter>
                        </div>

                        {/* <div
                          className="mperson_reading_item_5"
                          onClick={() => {
                            window.open(
                              'https://goerli.etherscan.io/tx/' +
                              item.txnhash
                            )
                          }}>
                          wallet:{item.senderAddress}
                        </div> */}

                         <div className="mperson_reading_item_5" >
                          {item.senderAddress}
                          </div>


                      </div>

                    </div>

                  )
                })
              ) : (
                <div></div>
              )}
            </div>

          </div>
        )
      })
    ) : (
    	<div style={{width:"100%",justifyContent:"center",display:"flex"}} > <div  className="mno_list" >You don’t have any donation yet!</div></div>
    ):"" }


{activeKey == 'Every.org' ?everyOrgList && everyOrgList.length > 0 ? (
      everyOrgList.map((item: any, index: any) => {
        return (
          <div key={index} className="mcperson_reading">
            <div style={{ display: 'flex' }} className="mperson_donation_projcet" >
                 <div key={index} className="mperson_reading_item">
                      <div className="mperson_reading_item_line" >
                        <div className="mperson_reading_item_3">
                          {index + 1}
                        </div>
                        <div className="mperson_reading_item_3 " style={{ marginLeft: "2.6667vw" }} >
                          {JSON.parse(item.everyOrgToNonprofit).name}
                        </div>
                        <div className="mperson_reading_item_3 " style={{ marginLeft: "2.6667vw" }} >
                           Donated   {  `  $`+item.usdAmount} 
                        </div>
                      </div>

                      <div className="mperson_reading_item_line" style={{ paddingLeft: "5.0333vw", color: "#808080" }} >
                        <div >
                          <Formatter
                            dateTime={item.createDateTime}></Formatter>
                        </div>
                        <div  className="mperson_reading_item_5" >
                               {item.everyOrgTags}
                        </div>

                      </div>

                    </div>
                
            </div>
          </div>
        )
      })
    ) : (
    	<div style={{width:"100%",justifyContent:"center",display:"flex"}} > <div  className="mno_list" >You don’t have any donation yet!</div></div>
    ):"" }


  </div>

  return Common.isMobile ? Mobile : Web
}
