import { useEffect, useState } from "react";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Input, message ,Checkbox,Modal,Upload} from 'antd';
import { Link, useNavigate,useLocation  } from 'react-router-dom'
import axios from 'axios';
import { State, useAppDispatch, useAppSelector } from "../../app/Store";
import { ChainId, ethereum, web3 } from "../../app/Config";
import {
	ConnectSelectors,
	ConnectTask,
	disconnect,
	SwitchTask,
	updateAddress,
	updateBalance,
	updateChainId
 } from "../../slices/ConnectSlice";
 import { Common } from "../../app/Common";
 import {httpGet,httpPost,host_url} from '../../utils/httpUtils';
import { type } from "os";
export function CSignUp() {




	const dispatch = useAppDispatch();
	const isConnected = useAppSelector(ConnectSelectors.isConnected);
	const isLoading = useAppSelector(ConnectSelectors.isLoading);
	const displayAddress = useAppSelector(ConnectSelectors.displayAddress);
	const { address, chainId } = useAppSelector(ConnectSelectors.userData);

		let heder_img=[
				{
				 img:'https://www.impactopia.net/impact-img/img/2023/04/21/a22034c1052946fa9cda165e9cf23b67.png',
				 img_active:'https://www.impactopia.net/impact-img/img/2023/05/26/ed90e43d485a4805ace437dacef5b26a.png',
				},
				{
				 img:'https://www.impactopia.net/impact-img/img/2023/04/21/11ebdd071893453cadfd2520673649cc.png',
				 img_active:"https://www.impactopia.net/impact-img/img/2023/05/26/4a21d71cdd6041adb2cbae7a807fa5b7.png"
				},{
				 img:'https://www.impactopia.net/impact-img/img/2023/04/21/e255e8897b6744df86a60b5a4f992904.png',
				 img_active:"https://www.impactopia.net/impact-img/img/2023/05/26/6fe351ff9f7445f29193d9661e3f10a4.png"
				}
		]

	const text = isLoading ? "CONNECTING..." :
	isConnected ? chainId === ChainId ?
	  'wallet sign' : "SWITCH" : "CONNECT";

	function connect() {
		!isConnected && !isLoading && dispatch(ConnectTask());
	  }
	  function switch_() {
		dispatch(SwitchTask())
	  }

	  async function sign_wallet(){
		const signature =  await web3.eth.personal.sign("welcome to impact", address,"welcome to impact");
		httpPost('/member/bindWallet',{address:address,signature:signature,message:"welcome to impact"}).then(
			(e:any) => {
				
			  if(e.data.code == 0){
				let  pageFrom = localStorage.getItem("pageFrom");
				if(pageFrom){
					localStorage.removeItem("pageFrom");
					window.location.href=host_url+pageFrom
				}else{
					navigate("/");
				}
			  }
			}
		)
	  }

	  const onClick = isLoading ? undefined :
		isConnected ? chainId === ChainId ?
		sign_wallet : switch_ : connect;



	const navigate = useNavigate();
	interface MemberInfo{
	   userName:any,
	   password:any,
	   email:any,
	   emailCaptcha:any,
	   twitter:any,
	   googleUuid:any,
	   checked:false,
	   headImg:any,
	   headImgActive:any

	}
	const [member,setMamber] =  useState({}as MemberInfo );
	const [step,setStep] =  useState(1);
	let query = new URLSearchParams(useLocation().search);
	
	useEffect(()=>{
		if(query.get("page")){
			localStorage.setItem("pageFrom",query.get("page")+"")
		}
		let  twitter =query.get("twitter");
		let  googleUuid =query.get("googleUuid");
		let  email =query.get("email");
		let  token =query.get("token");

		if(token){
			setStep(2);
			localStorage.setItem("token",token);
		}
		member.googleUuid=googleUuid;
		member.email=email;
		member.twitter=twitter;
		setMamber({...member});
	},[]);

	function Send(){
		if(!member.email){
			message.error("email is Required ");
			return;
		}
		httpGet('/member/sendEmailCaptcha/'+member.email).then(
			( e:any) => {
			   if(e.data.code == 0){
				  message.success("Send Email Captcha Successful");
			   }else{
				 message.error(e.data.msg);
			   }
			 }
		 )

	}

	const [terms,setTerms] =  useState(false);
	//0 fail  1 centent
	const [termsType,setTermsType] =  useState(0);


	function uploadChange(info:any){
		if (info.file.status === 'done') {
			if(info.file.response.code == 0 ){
				member.headImg=info.file.response.url;
				member.headImgActive=info.file.response.url;
				setMamber({...member});
			}
		  }
	}

	async function signUp(){
		if(!member.email){
			message.error("email is Required ");
			return;
		}
		if(!member.password){
			message.error("password is Required ");
			return;
		}

		if(!member.emailCaptcha){
			message.error("emailCaptcha is Required ");
			return;
		}

		if(!member.checked){
			setTerms(true);
			setTermsType(0);
			return;
		}


		httpPost('/member/registered',{...member}).then( ( e:any) => {
			 if(e.data.code == 0){
				localStorage.setItem("token",e.data.token);
				localStorage.setItem("member",JSON.stringify(e.data.member));
				setStep(2);
				setMamber({...e.data.member});
			 }else{
				message.error(e.data.msg);
			 }
		   }
	   )
	}

	const onChange = (e: any) => {
		console.log(`checked = ${e.target.checked}`);
		member.checked=e.target.checked;
		setMamber({...member});
	  }

	function  render (){
		httpPost('/twitter/render',{}).then(
			( e:any) => {
			   window.open(e.data.data);
			 }
		 )
	}

	function  renderGoogle(){
		
		httpPost('/oauth/render',{page:query.get("page")}).then(
			( e:any) => {
			   window.open(e.data.data);
			 }
		 )
	}

	const [loading, setLoading] = useState(false);
	const uploadButton = (
		<div>
		  {/* {loading ? <LoadingOutlined /> : <PlusOutlined />} */}
		</div>
	  );

	function  selectImg(e:any){
		 member.headImg=e.img_active;
		 member.headImgActive=e.img_active;
		 setMamber({...member});
	  }

	  function ok(){
		if(!member.headImg){
			message.error("please upload choose an avatar ");
			return;
		}
		httpPost("/member/uptData",{...member}).then((e:any)=>{
			if(e.data.code == 0 ){
				message.success("Account Registration Successful");
				over();
			}
		})
	  }

	  function over(){
		let  pageFrom = localStorage.getItem("pageFrom");
		if(query.get("page")){
			window.location.href=host_url+"/#/"+query.get("page")
		}else if(pageFrom){
			localStorage.removeItem("pageFrom");
			window.location.href=host_url+pageFrom
		}else {
			navigate("/");
		}
	  }

	let Web= <div className="sign_body" >
		<Modal closable={false}  width={"640px"} style={{borderRadius:"20px"}}  bodyStyle={{ borderRadius:"20px",backgroundColor: "transparent", width: "100%"}} footer={null} onOk={()=>{setTerms(false)}} onCancel={()=>{setTerms(false)}}  open={terms}>
				{termsType==0?<div className="terms_boday"  >
					<div className="terms_boday_un" >
						Please choose to agree terms and conditions first!
					</div>
					<div   onClick={()=>{ setTerms(false) }} className="terms_boday_ok" >
						ok
					</div>
				</div>:<div className="terms_boday"  >
					<div className="terms_boday_tile" >
						Terms and Conditios
					</div>
					<div className="terms_boday_content" >
				<p>	1.Introduction Welcome to Impactopia.net, a website owned and operated by Impactopia Corp. By using our website, you agree to these terms and conditions. If you do not agree to these terms and conditions, please do not use our website.</p>
				<p>2.Use of the Website You may use our website only for lawful purposes and in accordance with these terms and conditions. You may not use our website in any way that violates any applicable federal, state, local, or international law or regulation.</p>
				<p>3.Intellectual Property All content and materials on our website, including but not limited to text, graphics, logos, images, and software, are the property of Impactopia Corp or its licensors and are protected by United States and international copyright, trademark, and other intellectual property laws.</p>
				<p>4.User Contributions You may submit comments, suggestions, or other feedback to our website ("User Contributions"). By submitting User Contributions, you grant Impactopia Corp a non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such User Contributions throughout the world in any media.</p>
				<p>5.Disclaimer of Warranties THE CONTENT AND SERVICES PROVIDED ON OUR WEBSITE ARE PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. Impactopia Corp DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</p>
				<p>6.Limitation of Liability IN NO EVENT SHALL Impactopia Corp, ITS AFFILIATES, LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR PUNITIVE DAMAGES ARISING OUT OF OR RELATED TO YOUR USE OF OUR WEBSITE, INCLUDING BUT NOT LIMITED TO LOSS OF REVENUE, PROFITS, OR BUSINESS, EVEN IF Impactopia Corp HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
				<p>7.Indemnification You agree to indemnify, defend, and hold harmless Impactopia Corp. its affiliates, licensors, service providers, employees, agents, officers, and directors from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or related to your use of our website or your violation of these terms and conditions.</p>
				<p>8.Governing Law These terms and conditions shall be governed by and construed in accordance with the laws of the State of Delaware, without giving effect to any choice or conflict of law provision or rule.</p>
				<p>9.Dispute Resolution Any dispute arising out of or related to these terms and conditions, or your use of our website shall be resolved through arbitration in accordance with the rules of the American Arbitration Association. The arbitration shall take place in Dover, Delaware, and the award may be entered in any court having jurisdiction.</p>
				<p>10.You acknowledge that Impactopia is entitled to delete and modify materials that are deemed to be offensive, inappropriate or irrelevant to the target audience of the platform.</p>
				<p>11.Modification of Terms and Conditions Impactopia Corp. may modify these terms and conditions at any time, and such modifications shall be effective immediately upon posting on our website. Your continued use of our website after such modifications shall constitute your acceptance of the modified terms and conditions.</p>
				<p>12.Termination Impactopia Corp. may terminate your access to our website at any time, for any reason that breaks the terms and conditions above.</p>
					</div>
					<div   onClick={()=>{ setTerms(false) }} className="terms_boday_ok" >
						ok
					</div>
				</div>}

		</Modal>





	<div className="sign_left" onClick={over} ></div>
	<div className="sign_right" >
		<div className="sign_create" >
			Create Account
		</div>
		{step==2?<div>
			<div className="sign_sign_name" >choose an avatar</div>

			<div   style={{justifyContent:"left",}} >
				{heder_img.map((item:any,inx:any)=>{
					return 	<img  onClick={(e)=>{selectImg(item)}}   src={item.img_active==member.headImg?item.img_active:item.img}  style={{marginTop:'1.8229vw',width:'8.3333vw',height:'8.3333vw',marginLeft:"0.825vw"}}  alt="" />
				})}
			</div>

			<div className="sign_or">
				-OR-
			</div>

			<div style={{display:"flex",alignItems:"center"}} >
				<div  className="sign_sign_header_box"  >
					{member.headImgActive ? <img src={member.headImgActive} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
				</div>
				<Upload
					action={host_url+"/api/app/oss/upload"}
					showUploadList={false}
					maxCount={1}
					onChange={uploadChange}
				>
				<div className="person_upload_file" >
					Upload new picture
				</div>
				</Upload>

			</div>

			<div style={{display:"flex",alignItems:"center",marginTop:"4.375vw"}} >
				<div className="sign_sign_ok" onClick={ok}>OK</div>
				<div className="sign_sign_over"  onClick={over} >Back</div>
			</div>


		</div>:<div></div>}


		{step ==1?<div>
			<div className="sign_other" >
			<div className="sign_or">
				<img  onClick={renderGoogle} src={require("../../assets/v2/g@2x.png")} style={{width:'68px',height:'68px'}}  alt="" />
			</div>
			{/*<div className="sign_ds">*/}
				{/*<img onClick={render} src={require("../../assets/v2/t@2.png")} style={{width:'68px',height:'68px',marginLeft:"3.125vw"}}  alt="" />*/}
			{/*</div>*/}
			{/* <div className="sign_ds">
				<img src={require("../../assets/v2/l@2x.png")} style={{width:'68px',height:'68px',marginLeft:"3.125vw"}}  alt="" />
			</div> */}
			</div>
			<div className="sign_or">
				-OR-
			</div>

			<div className="sign_full_name">
				<Input placeholder="Full Name" onChange={(e)=>{
					member.userName=e.target.value;
					setMamber({...member});
				}} bordered={false}  className="sign_input" style={{width:"29.1667vw",display:"flex",alignItems:"flex-end"}} />
			</div>

			<div className="sign_full_email">
				<Input placeholder="Email Address"
				  value={member.email}
				onChange={(e)=>{
					member.email=e.target.value;
					setMamber({...member});
				}}  bordered={false} style={{width:"29.1667vw"}} />
			</div>

			<div className="sign_full_password">
				<Input placeholder="Password"  type="password" onChange={(e)=>{
					member.password=e.target.value;
					setMamber({...member});
				}}   bordered={false} style={{width:"29.1667vw"}} />
			</div>

			<div   style={{marginTop: 12}} >
				<div className="sign_send_but"  onClick={Send} >
					Send verification code
				</div>
			</div>


			<div className="sign_full_password">
				<Input placeholder="Fill in the verification code" onChange={(e)=>{
					member.emailCaptcha=e.target.value;
					setMamber({...member});
				}}  bordered={false} style={{width:"20.1667vw"}} />

				

			</div>
			<div className="sign_but but-shadow"  onClick={signUp} >
				Create Account
			</div>
			<div className="sign_to_login">
			<div  style={{width:"70%",textAlign:"left"}}  >
				<Checkbox onChange={onChange} style={{marginRight:"5px"}} ></Checkbox>
				Already read and agree <span  className="sign_to_login_1" onClick={()=>{setTerms(true); setTermsType(1);}} > terms and conditions .</span>
			</div>
			<div  style={{width:"30%",textAlign:"right"}}  >
			<Link  to={"/SignIn"} > <span  className="sign_to_login_1" >Sign In</span> </Link>
			</div>
		</div>
		</div>:<div></div>}
	</div>
</div>


let  Mobile=<div>

			<Modal closable={false}  width={"640px"} style={{borderRadius:"20px"}}  bodyStyle={{ borderRadius:"20px",backgroundColor: "transparent", width: "100%"}} footer={null} onOk={()=>{setTerms(false)}} onCancel={()=>{setTerms(false)}}  open={terms}>
				{termsType==0?<div className="terms_boday_m"  >
					<div className="terms_boday_un" >
						Please choose to agree terms and conditions first!
					</div>
					<div   onClick={()=>{ setTerms(false) }} className="terms_boday_ok" >
						ok
					</div>
				</div>:<div className="terms_boday_m"  >
					<div className="terms_boday_tile_m" >
						Terms and Conditios
					</div>
					<div className="merms_boday_content" style={{marginTop:20}} >
				<p>	1.Introduction Welcome to Impactopia.net, a website owned and operated by Impactopia Corp. By using our website, you agree to these terms and conditions. If you do not agree to these terms and conditions, please do not use our website.</p>
				<p>2.Use of the Website You may use our website only for lawful purposes and in accordance with these terms and conditions. You may not use our website in any way that violates any applicable federal, state, local, or international law or regulation.</p>
				<p>3.Intellectual Property All content and materials on our website, including but not limited to text, graphics, logos, images, and software, are the property of Impactopia Corp or its licensors and are protected by United States and international copyright, trademark, and other intellectual property laws.</p>
				<p>4.User Contributions You may submit comments, suggestions, or other feedback to our website ("User Contributions"). By submitting User Contributions, you grant Impactopia Corp a non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such User Contributions throughout the world in any media.</p>
				<p>5.Disclaimer of Warranties THE CONTENT AND SERVICES PROVIDED ON OUR WEBSITE ARE PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. Impactopia Corp DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</p>
				<p>6.Limitation of Liability IN NO EVENT SHALL Impactopia Corp, ITS AFFILIATES, LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR PUNITIVE DAMAGES ARISING OUT OF OR RELATED TO YOUR USE OF OUR WEBSITE, INCLUDING BUT NOT LIMITED TO LOSS OF REVENUE, PROFITS, OR BUSINESS, EVEN IF Impactopia Corp HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
				<p>7.Indemnification You agree to indemnify, defend, and hold harmless Impactopia Corp. its affiliates, licensors, service providers, employees, agents, officers, and directors from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or related to your use of our website or your violation of these terms and conditions.</p>
				<p>8.Governing Law These terms and conditions shall be governed by and construed in accordance with the laws of the State of Delaware, without giving effect to any choice or conflict of law provision or rule.</p>
				<p>9.Dispute Resolution Any dispute arising out of or related to these terms and conditions, or your use of our website shall be resolved through arbitration in accordance with the rules of the American Arbitration Association. The arbitration shall take place in Dover, Delaware, and the award may be entered in any court having jurisdiction.</p>
				<p>10.You acknowledge that Impactopia is entitled to delete and modify materials that are deemed to be offensive, inappropriate or irrelevant to the target audience of the platform.</p>
				<p>11.Modification of Terms and Conditions Impactopia Corp. may modify these terms and conditions at any time, and such modifications shall be effective immediately upon posting on our website. Your continued use of our website after such modifications shall constitute your acceptance of the modified terms and conditions.</p>					
					</div>
					<div   onClick={()=>{ setTerms(false) }}  style={{marginTop:20}} className="terms_boday_ok_m" >
						ok
					</div>
				</div>}
		</Modal>

<div className="sign_right_m" >
		<div className="sign_create_m" >
			Create Account
		</div>
		{step==2?<div style={{width:"100%",display:"flex",flexWrap:"wrap",justifyContent:"center"}} >
			<div className="sign_create_name_m" >choose an avatar</div>

			<div   style={{display:"flex",width:"100%",marginTop:40,marginBottom:40}} >

				{heder_img.map((item:any,inx:any)=>{
					return 	<img  onClick={(e)=>{selectImg(item)}}   src={item.img_active==member.headImg?item.img_active:item.img}  className="sign_sign_header_no"  alt="" />
				})}
			</div>


			<div className="sign_or_m">
				-OR-
			</div>

			<div style={{marginTop:40,display:"flex",justifyContent:"center",alignItems:"center",width:"100%"}} >
				<div  className="sign_sign_header_box_m"  >
					{member.headImg ? <img src={member.headImg} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
				</div>
				<Upload
					action={host_url+"/api/app/oss/upload"}
					showUploadList={false}
					maxCount={1}
					onChange={uploadChange}
				>
				<div className="person_upload_file_m" >
					Upload new picture
				</div>
				</Upload>
			</div>


			<div style={{display:"flex",alignItems:"center",marginTop:200}} >
				<div className="sign_sign_ok_m" onClick={ok}>OK</div>
				<div className="sign_sign_over_m"  onClick={over} >Back</div>
			</div>
		</div>:<div></div>}
		{step ==1?<div style={{width:"100%",display:"flex",flexWrap:"wrap",justifyContent:"center"}} >
			<div className="sign_other" >
			<div className="sign_ds">
				<img  onClick={renderGoogle} src={require("../../assets/v2/g@2x.png")} style={{width:'68px',height:'68px',marginLeft:"0.825vw"}}  alt="" />
			</div>
			{/*<div className="sign_ds">*/}
				{/*<img onClick={render} src={require("../../assets/v2/t@2.png")} style={{width:'68px',height:'68px',marginLeft:"3.125vw"}}  alt="" />*/}
			{/*</div>*/}
			{/* <div className="sign_ds">
				<img src={require("../../assets/v2/l@2x.png")} style={{width:'68px',height:'68px',marginLeft:"3.125vw"}}  alt="" />
			</div> */}
			</div>
			<div className="sign_or_m">
				-OR-
			</div>

			<div style={{marginTop:30,display:"flex",justifyContent:"flex-start",width:"100%",paddingLeft:10}} >
			Full Name
		</div>

			<div className="sign_full_name_m">
				<Input placeholder="Full Name" onChange={(e)=>{
					member.userName=e.target.value;
					setMamber({...member});
				}} bordered={false}  className="sign_input" style={{display:"flex",alignItems:"flex-end"}} />
			</div>

			<div style={{marginTop:30,display:"flex",justifyContent:"flex-start",width:"100%",paddingLeft:10}} >
			Email Address
		</div>

			<div className="sign_full_name_m">
				<Input placeholder="Email Address"
				  value={member.email}
				onChange={(e)=>{
					member.email=e.target.value;
					setMamber({...member});
				}}  bordered={false}  />
			</div>

			<div style={{marginTop:30,display:"flex",justifyContent:"flex-start",width:"100%",paddingLeft:10}} >
			Password
		</div>


			<div className="sign_full_name_m">
				<Input placeholder="Password"  type="password" onChange={(e)=>{
					member.password=e.target.value;
					setMamber({...member});
				}}   bordered={false}  />
			</div>

		<div style={{marginTop:30,display:"flex",justifyContent:"flex-start",width:"100%",paddingLeft:10}} >
				<div className="sign_send_but_m"  onClick={Send} >
					Send verification code
				</div>
		</div>

			<div className="sign_full_name_m">
				<Input placeholder="Fill in the verification code" onChange={(e)=>{
					member.emailCaptcha=e.target.value;
					setMamber({...member});
				}}  bordered={false}  />

			
			</div>

			<div className="sign_but_m"  onClick={signUp} >
				Create Account
			</div>
			<div className="sign_to_login_m">
			<div    >
				<Checkbox onChange={onChange} style={{marginRight:"5px"}} ></Checkbox>
				Already read and agree <span  className="sign_to_login_1" style={{fontSize:"2.2vw"}} onClick={()=>{setTerms(true); setTermsType(1);}} > terms and conditions .</span>
			</div>
			{/* <div  style={{width:"30%",textAlign:"right"}}  >
			<Link  to={"/SignIn"} > <span  className="sign_to_login_1" >Sign In</span> </Link>
			</div> */}
		</div>
		</div>:<div></div>}
	</div>
</div>

return Common.isMobile ? Mobile : Web


}
