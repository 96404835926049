import { useEffect, useState } from "react";
import { Input,message} from 'antd';
import { Link, useNavigate ,useLocation} from 'react-router-dom'
import {httpGet,httpPost,web_url} from '../../utils/httpUtils';
import { Common } from "../../app/Common";
import { web3 } from '../../app/Config';

export function CSignIn() {


	let query = new URLSearchParams(useLocation().search);
	let  page =query.get("page");


	
	  const navigate = useNavigate();
     interface MemberInfo{
		userName:any,
		password:any,
		email:any
	 }
	 const [member,setMamber] =  useState({}as MemberInfo );
	 async function signIn(){
		localStorage.removeItem("token");
		await httpPost('/member/login',{...member}).then(
			(e:any) => {
			  if(e.data.code == 0){
				localStorage.setItem("token",e.data.token);
				localStorage.setItem("member",JSON.stringify(e.data.member));
				 let  pageFrom = localStorage.getItem("pageFrom");
				 if(query.get("page")){
					window.location.href=web_url+"/#/"+query.get("page")
				}else  if(pageFrom){
					localStorage.removeItem("pageFrom");
					window.location.href=web_url+pageFrom
				}else{
					navigate("/");
				}
			  }else{
				message.error("Wrong username/password combination");
			  }
			}
		)
	 }

	 function  render (){
		httpPost('/twitter/render',{}).then(
			( e:any) => {
			   window.open(e.data.data);
			 }
		 )
	}

	function  renderGoogle(){
		httpPost('/oauth/render',{}).then(
			( e:any) => {
			   window.open(e.data.data);
			 }
		 )
	}

	let Web= <div className="sign_body" >
	<div className="sign_left" onClick={()=>{
		 let  pageFrom = localStorage.getItem("pageFrom");
		 if(pageFrom){
			 localStorage.removeItem("pageFrom");
			 window.location.href=web_url+pageFrom
		 }else{
			 navigate("/");
		 }
	}} ></div>
	<div className="sign_right" >
		<div className="sign_create" >
			Sign In Impactopia
		</div>

		<div className="sign_or" >
			<div className="sign_ds shou">
				<img   onClick={renderGoogle} src={require("../../assets/v2/g@2x.png")} style={{width:'68px',height:'68px'}}  alt="" />
			</div>
		</div>

		<div className="sign_or">
			-OR-
		</div>

		<div className="sign_full_name">
			<Input  value={member.userName} onChange={(e)=>{
				 member.email=e.target.value;
                 setMamber({...member});
			}}
			 placeholder="Email Address"  bordered={false}  className="sign_input" style={{width:"29.1667vw",display:"flex",alignItems:"flex-end"}} />
		</div>

		<div className="sign_full_email">
			<Input value={member.password} onChange={(e)=>{
				 member.password=e.target.value;
                 setMamber({...member});
			}}  placeholder="Password"  type="password"  bordered={false} style={{width:"29.1667vw"}} />
		</div>

		<Link  to={"/ForgotPassword"} >
		<div className="sign_to_login">
			 <span  className="sign_to_login_2" >Forgot password?</span>
		</div>
		</Link>

		<div className="sign_but but-shadow"  onClick={signIn} >
			Sign In
		</div>
		<div className="sign_to_login">
			Not have an account yet? <Link  to={"/SignUp"} > <span  className="sign_to_login_1" >Sign Up</span></Link>
		</div>
	</div>
</div>

let  Mobile=<div>
	<div className="sign_right_m" >
		<div className="sign_create_m" >
			Sign In Impactopia
		</div>
		<div className="sign_other" >
			<div className="sign_or_m">
				<img   onClick={renderGoogle} src={require("../../assets/v2/g@2x.png")} style={{width:'68px',height:'68px',marginLeft:"0.825vw"}}  alt="" />
			</div>
		</div>
		<div className="sign_or_m">
			-OR-
		</div>

		<div style={{marginTop:60,display:"flex",justifyContent:"flex-start",width:"100%",paddingLeft:10}} >
			Email Address
		</div>
		<div className="sign_full_name_m">
			<Input  value={member.userName} onChange={(e)=>{
				 member.email=e.target.value;
                 setMamber({...member});
			}}
			 placeholder="Email Address"  bordered={false}  className="sign_input" style={{display:"flex",alignItems:"flex-end",backgroundColor: "transparent"}} />
		</div>
		<div>
		</div>

		<div style={{marginTop:42,display:"flex",justifyContent:"space-between",width:"100%",paddingLeft:10,paddingRight:10}} >
			<div>Password</div>
			<div className="sign_to_login">
				<Link  to={"/ForgotPassword"} >
				<span  className="sign_to_login_2_m" >Forgot password?</span>
				</Link>
			</div>
		</div>

		<div className="sign_full_name_m"  >
			<Input value={member.password} onChange={(e)=>{
				 member.password=e.target.value;
                 setMamber({...member});
			}}  placeholder="Password"  type="password"  bordered={false}  />
		</div>

		<div className="sign_but_m"  onClick={signIn} >
			Sign In
		</div>
		<div className="sign_to_login_m">
			Not have an account yet? <Link  to={"/SignUp"} > <span  className="sign_to_login_1"   style={{fontSize:"2.2vw"}} >Sign Up</span></Link>
		</div>
	</div>
</div>
return Common.isMobile ? Mobile : Web
}
