import { useEffect, useState } from "react";
import { Input,message,Select,Drawer,InputNumber , Button, Popover,Tabs,Modal,Steps } from 'antd';
import { Popup } from 'antd-mobile'
import "./PushDonate.css";
import type { MenuProps } from 'antd';
import {httpGet,httpPost} from '../../utils/httpUtils';
import { State, useAppDispatch, useAppSelector } from "../../app/Store";
import { ChainId, ethereum, web3 } from "../../app/Config";
import {getImpactManger,getErc20Contract} from '../../app/Contract';
import impactManger from "../../contracts/ImpactManger.json";
import {CSignIn_min} from "./CSignIn_min";
import { Common } from "../../app/Common";
import { Link, useNavigate ,useLocation } from 'react-router-dom'
import {
	ConnectSelectors,
	ConnectTask,
	disconnect,
	SwitchTask,
 } from "../../slices/ConnectSlice";

 import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import { Project } from "../pages/Project";


type IProps = {
    handleClick: () => void,
	member?:any,
	project?:any,
	fontSize?:any,
	pages:any
}

export function PushDonate(props:IProps) {
	const navigate = useNavigate();
	let fontSize= props.fontSize;
	const dispatch = useAppDispatch();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalOpenStatus, setModalOpenStatus] = useState(false);
	const [donationMoney, setDonationMoney] = useState(false);
	const [memberData,setMemberData] =  useState({}as any);
	const [project,setProject] =  useState({}as any); 
	const isConnected = useAppSelector(ConnectSelectors.isConnected);
	const isLoading = useAppSelector(ConnectSelectors.isLoading);
	const displayAddress = useAppSelector(ConnectSelectors.displayAddress);
	const { address, chainId } = useAppSelector(ConnectSelectors.userData);
	const [donationOpen,setDonationOpen] =  useState(false);
	const [payType,setPayType] =  useState("Cryptocurrency");
	let query = new URLSearchParams(useLocation().search);
	 function connect() {
		!isConnected && !isLoading && dispatch(ConnectTask());
	  }
	  function switch_() {
		dispatch(SwitchTask())
	  }
	  function Disconnect() {
		dispatch(disconnect())
	  }
	  async function donation() {
		if(project.isErc20){
			const contract = await getImpactManger();
			debugger
			let  donationMoney=new Number(project.donationMoney*project.receivingTokenDigits);
			contract.methods.donation(project.id,memberData.id,donationMoney.toString()).send({
			  from: address
			}).on('error', (error: any) => {
			  message.error(error);
			  showModal(project.donationMoney,false);
			}).on('transactionHash', (txHash: any) => {
			  console.warn("transactionHash", txHash);
			}).on('receipt', (receipt: any) => {
			  message.success("Success");
			  props.handleClick();
			  showModal(project.donationMoney,true)
			})
		}else{
			const contract = await getImpactManger();
			contract.methods.donation(project.id,memberData.id,web3.utils.toWei("0")).send({
			  from: address,
			  value: web3.utils.toWei(project.donationMoney ?project.donationMoney+"":"0")
			}).on('error', (error: any) => {
			  message.error(error);
			  showModal(project.donationMoney,false);
			}).on('transactionHash', (txHash: any) => {
			  console.warn("transactionHash", txHash);
			}).on('receipt', (receipt: any) => {
			  message.success("Success");
			  showModal(project.donationMoney,true);
			  props.handleClick();

			})
		}
	  }


	async function approveErc20() {
		const contract = await getErc20Contract(project.receivingTokenAddress);
		contract.methods.approve(impactManger.address, new Number(99999999999*project.receivingTokenDigits).toString()).send({
		  from: address
		}).on('error', (error: any) => {
		  message.error(error);
		  getErc20Approve();
		}).on('transactionHash', (txHash: any) => {
		  console.warn("transactionHash", txHash)
		}).on('receipt', (receipt: any) => {
		  message.success("Success");
		  getErc20Approve();
		})
	  }

	async function getErc20Approve() {
		if(project.receivingAddress && address ){
			const tokenBContract = await getErc20Contract(project.receivingTokenAddress);
			project.allowance = await tokenBContract.methods.allowance(address,impactManger.address ).call();

		}
	 }

	 async function getErc20Blane() {
		if(!address){
			connect();
		}

		if(project.receivingAddress && address ){
			if(project.isErc20){
				const tokenBContract = await getErc20Contract(project.receivingTokenAddress);
				let balanceOf =await tokenBContract.methods.balanceOf(address).call();
				if(balanceOf){
					project.balanceOf = new Number(balanceOf/project.receivingTokenDigits).toFixed(4);
				}else{
					project.balanceOf =0;
				}
			}else{
				web3.eth.getBalance(address, (err, wei) => {
					let balance = web3.utils.fromWei(wei, 'ether');
				   project.balanceOf = new Number(balance).toFixed(6);
				 })
			}

		}
	 }



	 function getMember(){
		httpGet("/member/getMember").then((e:any)=>{
			if(e.data.code == 0 ){
				setMemberData(e.data.data);
				const steps = [
					{
					title: 'Amount',
					content: 'First-content',
					},
					{
					title: 'Donate',
					content: 'Last-content',
					},
					];
				 setSteps(steps);
			}else{
				const steps = [
					{
					title: 'Amount',
					content: 'First-content',
					},
					{
					title: 'Sign up',
					content: 'Second-content',
					},
					{
					title: 'Donate',
					content: 'Last-content',
					},
					];
					setSteps(steps);
			}
		})
	 }

	 async function sign_wallet(){
		const signature =  await web3.eth.personal.sign("welcome to impact", address,"welcome to impact");
		httpPost('/member/bindWallet',{address:address,signature:signature,message:"welcome to impact"}).then(
			(e:any) => {
			  if(e.data.code == 0){
				getMember();
			  }
			}
		)
	  }

	  const [steps, setSteps] = useState([]as Array<any>);

	  useEffect(()=>{
			getMember();
			if(props.project){
				setProject({...props.project});
			}
			let  donate =query.get("donate");
			if(donate == 'success'){
				showModal(project.donationMoney,true);
			}else if(donate=='fail'){
				showModal(project.donationMoney,false);
			}
	},[props]);

	useEffect(()=>{
		if(isConnected &&chainId && address ){
			getErc20Approve();
			getErc20Blane();
		}
	},[isConnected,chainId]);

	  const text = isLoading ? "CONNECTING..." :
	  isConnected ? chainId === ChainId ?
		displayAddress : "SWITCH" : "CONNECT";

	  function textTre(text: any) {
		  return "SWITCH,CONNECT".indexOf(text) == -1;
	  }
	const onClick = isLoading ? undefined :
	  isConnected ? chainId === ChainId ?
		undefined : switch_ : connect;


		const showModal = (e:any,status:any) => {
			setIsModalOpen(true);
			setModalOpenStatus(status);
			setDonationMoney(e);
		 };

		const handleOk = () => {
			setIsModalOpen(false);
		};

		const handleCancel = () => {
			setIsModalOpen(false);
		};

 	 function  donationOpenWin(){
		Common.isMobile ? Mobile : Web
		if(Common.isMobile){
			httpGet("/member/getMember").then((e:any)=>{
				if(e.data.code != 0 ){
					localStorage.setItem("pageFrom",props.pages);
					navigate("/SignIn")
				}else{
					setDonationOpen(true);
				}
			})
		}else{
			setDonationOpen(true);
		}
	  }

	  const [current, setCurrent] = useState(0);
	  const next = (index:any) => {
		setCurrent(index);
	  };
	  
	  const items = steps.map(item => ({ key: item.title, title: item.title }));

	  const optionsToken = {
		'ETH':[
			{
				value: '1',
			  },
			  {
				value: '0.5',

			  },
			  {
				value: '0.2',

			  },
			  {
				value: '0.1',

			  },
		],
		'USDT':[
			{
				value: '1000',
			  },
			  {
				value: '500',

			  },
			  {
				value: '200',

			  },
			  {
				value: '100',

			  },
		]
	  } as any;
	  const onChange = (value: number) => {
		setCurrent(value);
	  };


	  function createdOrder(){
		httpPost("/donate/created",{projecrtId:project.id,amt:project.donationMoney}).then((e:any)=>{
			if(e.data.code == 0 ){
				window.open(`https://www.paypal.com/checkoutnow?token=${e.data.data}`);
			}
		})
	}

	  const ButtonWrapper = () => {
		return (<>
				<PayPalButtons
					style= {{"layout":"vertical"}}
					disabled={false}
					fundingSource={undefined}
					createOrder={async() => {
						return httpPost("/donate/created",{projecrtId:project.id,amt:project.donationMoney}).then((e:any)=>{
								if(e.data.code == 0 ){
									return e.data.data;
								}else{
									return ""
								}
							})
					}}
					onApprove={ async(data:any, actions:any)=> {
						return httpGet("/donate/capture/"+data.orderID).then((e:any)=>{
							message.success("Success");
							showModal(project.donationMoney,true);
							 props.handleClick();
						})
					}}
				/>
			</>
		);
	}



	let Web= <div className="my_post_body" >
			<div className="project_donate but-shadow"  style={{fontSize:fontSize?fontSize:"0.9375vw"}}  >
				<img src={require("../../assets/v2/donate@2x.png")}  style={{width: fontSize?fontSize:"1.1458vw",height:fontSize?fontSize:"1.1458vw",marginRight:"0.5208vw"}}  alt="" />
				<div  onClick={donationOpenWin} >Donate</div>
			</div>

	<Drawer title="Your Donation" placement="right"   width={"33.8542vw"} onClose={()=>{setDonationOpen(false)}} open={donationOpen}>

		<div  className="mproject_donate_popup_box_but" >
				<img src={require(payType !='Cryptocurrency'?"../../assets/mobile/eth.png":"../../assets/mobile/eth_active.png")} onClick={()=>{
					setPayType("Cryptocurrency")
					project.donationMoney="0.1";
					setProject({...project});
				}} className="project_donate_popup_box_but_img but-shadow "  alt="" />

				{project.paypalAccount?<img src={require(payType !='paypal'?"../../assets/mobile/paypal.png":'../../assets/mobile/paypal_active.png')} onClick={()=>{
					setPayType("paypal")
					project.donationMoney="100";
					setProject({...project});
				}}   className="project_donate_popup_box_but_img but-shadow "  alt="" />:""}
							
		</div>

		{payType=="Cryptocurrency" ?<div  style={{marginTop:"30px"}} > <div  className="push_donate_your" >
		<Steps current={current} items={items} onChange={onChange}  style={{width:500}}  size={"small"} />
		</div>
		<div  className="push_donate_box" >
		{current==0 ?   address?  <div  className="push_donate">
			<div  className="push_donate_your" >Choose your amount</div>
			<div  className="push_donate_list" >
				{project &&project.receivingToken? optionsToken[project.receivingToken].map((item:any,inx:any)=>{
					return 	<div  key={inx} className={project.donationMoney==item.value?"push_donate_but_active but-shadow":"push_donate_but but-shadow"}onClick={()=>{
						project.donationMoney=item.value;
						setProject({...project});
					}} >
					<span  className="push_donate_but_money"  >{item.value}</span> {project.receivingToken}
				  </div>
				}):""}
			</div>
			<div className="push_donate_but_min" >
				<InputNumber
					controls={false}
					style={{
						width: "18.75vw",
						height:"2.5vw"
					}}
					min="0"
					addonAfter={project.receivingToken}
					step="0.1"
					value={project.donationMoney}
					onChange={(e)=>{
						project.donationMoney=e;
						setProject({...project});
					}}
					/>
			</div>
			<div className="push_donaton_amont"  >You already own <span style={{color:"#52A553"}} >{project.balanceOf}</span> {project.receivingToken}, you can get <span style={{color:"#52A553"}}  onClick={()=>{}} > more →</span> </div>
			<div className="push_donaton_ok but-shadow"   onClick={()=>{
					if(memberData.id){
						next(2);
					}else{
						next(1);
					}
			}} >OK</div>
	  </div>:<div  className="push_donate_wallet"  >
			  <div  className="push_donate_your" >Wallet Connecting</div>
			   <div   >
			     <img src={require('../../assets/v2/metamask_bg.png')} onClick={connect} className="push_donate_metamask" ></img>
			   </div>
			   <div    >
			     <img src={require('../../assets/v2/walletconnect_bg.png')} className="push_donate_metamask" ></img>
			   </div>
	  </div>:""}


	  {current==1 ? <div  className="push_donate">
	  	<CSignIn_min   projectId={project.id} handleClick={()=>{
			next(2);
			localStorage.setItem("pageFrom",props.pages);
			getMember();
		}} ></CSignIn_min>
	  </div>:""}


	  {current==2 ? <div  className="push_donate">
					  <div  className="push_donate_will" >Your will  donate <span className="min_sign_to_login_1" >{project.donationMoney} </span> {project.receivingToken} </div>
			  {memberData && memberData.walletAddress?<div>
					{
					textTre(text) ?
					(	project.isErc20 ?( project.allowance > 0 ? <div onClick={()=>donation()}    className="donation_but" >
						{isLoading ? "Donate submit ":"Donate"}
						</div> :<div onClick={() => { approveErc20() }}  className="donation_but"  >Approval</div>) : <div onClick={()=>donation()}    className="donation_but" >Donate</div> )
					 :
					<div onClick={onClick}  className="donation_but" >{text}</div>
					}
				</div>:<div>
						{textTre(text) ? <div  className="donation_but"  onClick={sign_wallet} >Sign Wallet</div> :<div onClick={onClick}  className="donation_but" >{text}</div>}
				</div>}

			  </div>:""}
		</div>
	</div>
	:
	<div className="my_post_body"  style={{marginTop:"30px"}}>
		<div  className="push_donate_your" >
			<Steps current={current} items={items} onChange={onChange}  style={{width:500}}  size={"small"} />
		</div>
 		<div  className="push_donate_box" >
			{current==0 ? <div  className="push_donate">
			<div  className="push_donate_your" >Choose your amount</div>
			<div  className="push_donate_list" >
				{optionsToken['USDT'].map((item:any,inx:any)=>{
					return 	<div  key={inx} className={project.donationMoney==item.value?"push_donate_but_active":"push_donate_but"}  onClick={()=>{
						project.donationMoney=item.value;
						setProject({...project});
					}} >
					<span  className="push_donate_but_money"  > ${item.value}</span>
				  </div>
				})}
			</div>
			<div className="push_donate_but_min" >
				<InputNumber
					controls={false}
					style={{
						width: "18.75vw",
						height:"2.5vw"
					}}
					min="0"
					addonAfter={'$'}
					step="0.1"
					value={project.donationMoney}
					onChange={(e)=>{
						project.donationMoney=e;
						setProject({...project});
					}}
					/>
			</div>
			<div className="push_donaton_amont"  >You already own <span style={{color:"#52A553"}} >{project.balanceOf}</span>$, you can get <span style={{color:"#52A553"}}  onClick={()=>{}} > more →</span> </div>
			<div className="push_donaton_ok"   onClick={()=>{
				if(project.donationMoney){
					if(memberData.id){
						next(2);
					}else{
						next(1);
					}
				}else{
					message.error('Please confirm the donation amount');
				}
					
			}} >OK</div>
	  </div>:""}
	  {current==1 ? <div  className="push_donate">
	  	<CSignIn_min handleClick={()=>{
			next(2);
			getMember();
		}} ></CSignIn_min>
	  </div>:""}
	  {current==2 ? <div  className="push_donate">
	  <div  className="push_donate_will" >Your will  donate <span className="min_sign_to_login_1" >{project.donationMoney} </span> $ </div>
			{project.donationMoney ? 
				<div  className="push_donaton_ok"  onClick={createdOrder} >
					Donate
				</div>
				:<div >
					Please confirm the donation amount
				</div>}
		
	  </div>:""}
	  
	  </div>
	  	<Modal closable={false}   bodyStyle={{ borderRadius:"20px",backgroundColor: "transparent", width: "100%" }} footer={null} onOk={handleOk} onCancel={handleCancel}  open={isModalOpen}>
				{modalOpenStatus?<div className="success-img"  >
				<div className="flex-align-center">
					<div  className="donationMoney" >You have donated <span  className="donationMoney_num" >{donationMoney}</span> $ !</div>
				</div>
				<div className="flex-align-center">
					<div className="donationMoney_but" onClick={()=>{
						setIsModalOpen(false)
					}} >Continue</div>
				</div>
			</div>:<div className="failed-img"  >
				<div className="flex-align-center">
					<div  className="donationMoney" >Failed, please try again later !</div>
				</div>
				<div className="flex-align-center">
					<div className="donationMoney_fail_but" onClick={()=>{
						setIsModalOpen(false)
					}} >Try again</div>
				</div>
			</div>}

		</Modal> 
	</div>}

      </Drawer>

	  	<Modal closable={false}   bodyStyle={{ borderRadius:"20px",backgroundColor: "transparent", width: "100%" }} footer={null} onOk={handleOk} onCancel={handleCancel}  open={isModalOpen}>
				{modalOpenStatus?<div className="success-img"  >
				<div className="flex-align-center">
					<div  className="donationMoney" >You have donated <span  className="donationMoney_num" >{donationMoney}</span> {project.receivingToken} !</div>
				</div>
				<div className="flex-align-center">
					<div className="donationMoney_but" onClick={()=>{
						setIsModalOpen(false)
					}} >Continue</div>
				</div>
			</div>:<div className="failed-img"  >
				<div className="flex-align-center">
					<div  className="donationMoney" >Failed, please try again later !</div>
				</div>
				<div className="flex-align-center">
					<div className="donationMoney_fail_but" onClick={()=>{
						setIsModalOpen(false)
					}} >Try again</div>
				</div>
			</div>}

		</Modal>
	</div>


	let  Mobile =<div >
		<div  className="mproject_donate_but"  onClick={donationOpenWin} >
			Donate
		</div>

			<Popup  bodyClassName="mproject_donate_popup" visible={donationOpen} position='bottom' onMaskClick={() => {setDonationOpen(false)}} bodyStyle={{padding:"20px" }}>
				<div  className="mproject_donate_popup_box" >
					<div  className="mproject_donate_popup_box_but" >
						<img src={require(payType !='Cryptocurrency'?"../../assets/mobile/eth.png":"../../assets/mobile/eth_active.png")} onClick={()=>{
							setPayType("Cryptocurrency")
							project.donationMoney="0.1";
							setProject({...project});

						}} className="mproject_donate_popup_box_but_img"  alt="" />

						{project.paypalAccount?<img src={require(payType !='paypal'?"../../assets/mobile/paypal.png":'../../assets/mobile/paypal_active.png')} onClick={()=>{
							setPayType("paypal")
							project.donationMoney="100";
							setProject({...project});
						}}   className="mproject_donate_popup_box_but_img"  alt="" />:""}
						
					</div>
				</div>
				{payType=="Cryptocurrency"? (address ?
					<div>
							<div className="mproject_donate_name" >
								Choose your amount
							</div>
						<div className="mproject_donate_item_box" >
										<div className="mproject_donate_item_amonts" >
											{project &&project.receivingToken? optionsToken[project.receivingToken].map((item:any,inx:any)=>{
												return 	<div  key={inx} className={project.donationMoney ==item.value ?"mpush_donate_but_active":"mpush_donate_but" } onClick={()=>{
													project.donationMoney=item.value;
													setProject({...project});
												}} >
												{item.value} {project.receivingToken}
											</div>
											}):""}
												<InputNumber
												controls={false}
												className="mpush_donate_input"
												size="large"
												min="0"
												addonAfter={project.receivingToken}
												step="0.1"
												value={project.donationMoney}
												onChange={(e)=>{
													project.donationMoney=e;
													setProject({...project});
												}}
												/>
										</div>
										
										<div  className="mproject_donate_item_but" >

											{memberData && memberData.walletAddress?<div>
											{
											textTre(text) ?
											(	project.isErc20 ?( project.allowance > 0 ? <div onClick={()=>donation()}    >
												{isLoading ? "Donate submit ":"Donate"}
												</div> :<div onClick={() => { approveErc20() }}    >Approval</div>) : <div onClick={()=>donation()}    >Donate</div> )
											:
											<div onClick={onClick}  >{text}</div>
											}
										</div>:<div>
												{textTre(text) ? <div onClick={sign_wallet} >Sign Wallet</div> :<div onClick={onClick} >{text}</div>}
										</div>}
										</div>
								</div>
					</div>:
					
					<div   >
							<div  className="mproject_donate_name" >Wallet Connecting</div>
						<div  className="mproject_donate_item_box" >
								<div   >
								<img src={require('../../assets/v2/metamask_m_bg.png')} onClick={connect} className="mpush_donate_metamask" ></img>
							</div>
							<div  style={{marginTop:10}}   >
								<img src={require('../../assets/v2/walletconnect_m_bg.png')} className="mpush_donate_metamask" ></img>
							</div>
						</div>
							

					</div>)
				     :
					 
					 <div>
						<div className="mproject_donate_name" >
							Choose your amount
						</div>
						<div className="mproject_donate_item_box" >
							<div className="mproject_donate_item_amonts" >
								{project &&project.receivingToken? optionsToken["USDT"].map((item:any,inx:any)=>{
									return 	<div  key={inx} className={project.donationMoney ==item.value ?"mpush_donate_but_active":"mpush_donate_but" } onClick={()=>{
										project.donationMoney=item.value;
										setProject({...project});
									}} >
									${item.value}
								</div>
								}):""}
									<InputNumber
									controls={false}
									className="mpush_donate_input"
									size="large"
									min="0"
									addonAfter={'USD'}
									step="0.1"
									value={project.donationMoney}
									onChange={(e)=>{
										project.donationMoney=e;
										setProject({...project});
									}}
									/>
							</div>
							
							<div   style={{display:"flex",width:"100%",justifyContent:"center",marginTop:"30px"}} >
									{/* <PayPalScriptProvider
									options={{
										"client-id": "AfITQAssywbGLsagCqlGiWQ5HC57Qpv4FSVD0bNuqNUbmnHfIdMv0T3S_rnMoUNiieNolL37dEyY83ur",
										components: "buttons",
										currency: "USD"
									}}
									>
										<ButtonWrapper></ButtonWrapper>
									</PayPalScriptProvider> */}

									<div className="mproject_donate_item_but" onClick={()=>{
													createdOrder()
										}} >Continue</div>

							</div>
					</div>

						
					 </div>
					
					}
			</Popup>


			<Popup  bodyClassName="mproject_donate_popup2"  style={{height:""}}  visible={isModalOpen} position='bottom' onMaskClick={() => {setIsModalOpen(false)}} bodyStyle={{padding:"20px" }}>
					{modalOpenStatus?<div className="mproject_donate_popup_box"  >

						<div className="flex-align-center">
							<img  className="mproject_donate_popup_box_success" src={require("../../assets/mobile/success.png")} />
						</div>
						<div className="flex-align-center">
							<div className="mproject_donate_success">
								Success
							</div>
						</div>
						<div className="flex-align-center">
							<div  className="mdonationMoney" >You have donated <span  className="donationMoney_num" >{donationMoney}</span> {project.receivingToken} !</div>
						</div>
						<div className="flex-align-center" style={{ marginTop: "7.4667vw"}} >
							<div className="mdonationMoney_but" onClick={()=>{
								setIsModalOpen(false)
							}} >Good</div>
						</div>
					</div>:<div className="mproject_donate_popup_box"  >
						<div className="flex-align-center">
							<img  className="mproject_donate_popup_box_success" src={require("../../assets/mobile/failed.png")} />
						</div>
						<div className="flex-align-center">
							<div className="mproject_donate_failed">
								Failed
							</div>
						</div>
						<div className="flex-align-center">
							<div  className="mdonationMoney" >Failed, please try again later !</div>
						</div>
						<div className="flex-align-center">
							<div className="mdonationMoney_fail_but" onClick={()=>{
								setIsModalOpen(false)
							}} >Continue</div>
						</div>
					</div>}
			</Popup>

	</div>



	return Common.isMobile ? Mobile : Web
}
